<template>
  <div class="flex justify-between items-center">
    <app-heading>
      {{ t('ttmt.models.changelog', 2) }}
    </app-heading>
  </div>

  <div
    v-if="!changelogsLoading"
    class="mr-4"
  >
    <ul>
      <li
        v-for="changelog in changelogs"
        :key="changelog.resource.id"
        class="my-4"
      >
        <span class="block text-xs font-semibold">
          {{ changelog.resource.attributes.published_ago }}
        </span>

        <router-link :to="{ name: 'show-changelogs', params: { id: changelog.resource.id } }">
          {{ l10nTitle(changelog.resource) }}
        </router-link>
      </li>
    </ul>

    <p
      v-if="changelogs.length < 1"
      class="mt-6 text-center"
    >
      {{ t('ttmt.common.messages.no_records') }}
    </p>
  </div>

  <template v-if="lastPage && page < lastPage">
    <a
      v-if="nextPageFetching === false"
      href="#"
      class="block text-center app-link w-full"
      @click.prevent="nextPage"
    >
      {{ t('ttmt.common.actions.load_more') }}
    </a>
  </template>

  <div
    v-if="nextPageFetching"
    class="text-center w-full"
  >
    <app-spinner
      class="text-theme-500"
      size="lg"
    />
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import { fetchChangelogs as fetchApiChangelogs } from '@shared/http/api'
import useChangelog from '@shared/hooks/resources/changelog'
import AppHeading from '@app/components/ui/AppHeading.vue'
import AppSpinner from '@shared/components/ui/AppSpinner.vue'

const { t } = useI18n()

// ---------- CHANGELOGS ----------

const changelogs = ref([])
const changelogsLoading = ref(true)
const { l10nTitle } = useChangelog()

function fetchChangelogs(nextPage = false) {
  if (nextPage === false) {
    changelogsLoading.value = true
  }

  nextPageFetching.value = nextPage

  const params = {
    'page': page.value,
  }

  fetchApiChangelogs(params)
    .then((response) => {
      const oldChangelogs = changelogs.value
      const newChangelogs = response.data.data?.map((changelog) => ({
        resource: changelog,
      })) ?? []

      if (nextPage) {
        // add new results after the old ones
        changelogs.value = [...oldChangelogs, ...newChangelogs]
      } else {
        // replace the old results with the new ones
        changelogs.value = newChangelogs
      }

      lastPage.value = response.data.meta?.last_page
    })
    .finally(() => {
      nextPageFetching.value = false
      changelogsLoading.value = false
    })
}

// ---------- PAGINATION ----------

const nextPageFetching = ref(false)
const page = ref(1)
const lastPage = ref(null)

function nextPage() {
  page.value += 1
  fetchChangelogs(true)
}

// ---------- LIFECYCLES ----------

onMounted(() => {
  fetchChangelogs()
})
</script>
