<template>
  <template v-if="changelog && !changelogLoading">
    <div class="flex justify-between items-center">
      <app-heading>
        {{ l10nTitle(changelog) }}

        <span class="block text-xs font-semibold">
          {{ changelog.attributes.published_ago }}
        </span>
      </app-heading>
    </div>

    <div class="mt-4">
      <p>
        {{ t('ttmt.changelogs.show.version') }}
        {{ changelog.attributes.version }}
      </p>

      <!-- eslint-disable vue/no-v-html -->
      <div
        class="text-justify mt-4"
        v-html="l10nContent(changelog)"
      />
    </div>
  </template>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'

import { fetchChangelog as fetchApiChangelog } from '@shared/http/api'
import useChangelog from '@shared/hooks/resources/changelog'
import AppHeading from '@app/components/ui/AppHeading.vue'

const route = useRoute()
const { t } = useI18n()

const { id } = route.params

// ---------- CHANGELOG ----------

const changelog = ref()
const changelogLoading = ref(false)
const {
  l10nTitle,
  l10nContent,
} = useChangelog()

function fetchChangelog() {
  changelogLoading.value = true

  fetchApiChangelog(id)
    .then((response) => {
      changelog.value = response.data.data
    })
    .finally(() => {
      changelogLoading.value = false
    })
}

// ---------- LIFECYCLES ----------

onMounted(() => {
  fetchChangelog()
})
</script>
