import { marked } from 'marked'
import DOMPurify from 'dompurify'

import useLocale from '@shared/hooks/locale'

export default function useChangelog() {
  const { getAttributeTranslation } = useLocale()

  function l10nTitle(changelog) {
    return getAttributeTranslation(changelog?.attributes?.title)
  }

  function l10nContent(changelog) {
    const content = getAttributeTranslation(changelog?.attributes?.content)

    return DOMPurify.sanitize(
      marked(content ?? ''),
      {},
    )
  }

  return {
    l10nTitle,
    l10nContent,
  }
}
